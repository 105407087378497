import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import WhiteMainBackground from '../../../shared_components/WhiteMainBackground/WhiteMainBackground';
import ImageModal from '../ImageModal/ImageModal';
import Spinner from '../../../shared_components/Spinner/Spinner';
import ListHeadingWithSearch from '../../../shared_components/ListHeadingWithSearch/ListHeadingWithSearch';
import PaginationGroup from '../../../shared_components/PaginationGroup/PaginationGroup';
import styles from './ImageGrid.module.css';
import ImageService from '../../../services/ImageService';

const ImageGrid = ({
	handleSearchSubmit,
	filteredImages,
	setFilteredImages,
	loading,
	currentPage,
	totalPages,
	onPageChange,
	getImageUrl,
	jwtToken,
	userData,
	setCurrentPage,
}) => {
	const [modalOpen, setModalOpen] = useState(false);
	const [selectedImage, setSelectedImage] = useState(null);

	const navigate = useNavigate();

	const handleImageClick = (image) => {
		if (!image) {
			console.error('Attempted to click on undefined image');
			return;
		}

		if (!image.base64_medium) {
			ImageService.getImageById(jwtToken, userData, image.imageID)
				.then((fullImage) => {
					const imageWithMedium = { ...image, ...fullImage };
					setSelectedImage(imageWithMedium);
					setModalOpen(true);
				})
				.catch((error) => {
					alert(
						'Failed to load full image data. Please try again or refresh the page.'
					);
				});
		} else {
			setSelectedImage(image);
			setModalOpen(true);
		}
	};

	const closeModal = () => {
		setSelectedImage(null);
		setModalOpen(false);
	};

	const handleDownload = () => {
		ImageService.handleDownload(selectedImage, jwtToken, userData);
	};

	const handleCopyPrompt = () => {
		ImageService.handleCopyPrompt(selectedImage);
	};

	const handleDeleteImage = (imageId) => {
		ImageService.deleteImage(imageId, userData, jwtToken)
			.then(() => {
				return ImageService.getAllImages(
					jwtToken,
					userData,
					currentPage - 1,
					6
				);
			})
			.then((response) => {
				setFilteredImages(response.content);

				if (response.content.length === 0 && currentPage > 1) {
					setCurrentPage(currentPage - 1);
				}

				closeModal();
			})
			.catch((error) => {
				alert('Failed to delete the image. Please try again.');
			});
	};

	const handleViewImage = () => {
		if (selectedImage) {
			navigate(`/gallery/image/${selectedImage.imageID}`);
		}
	};

	return (
		<WhiteMainBackground className={styles.container}>
			<ImageModal
				isOpen={modalOpen}
				onClose={closeModal}
				prompt={selectedImage?.promptUsed}
				onDownload={() => handleDownload(selectedImage)}
				onCopy={handleCopyPrompt}
				onDelete={handleDeleteImage}
				onEdit={handleViewImage}
				imageId={selectedImage?.imageID}
				className={styles.modalImage}>
				{selectedImage && (
					<img
						src={`data:image/jpeg;base64,${selectedImage.base64_medium}`}
						alt='Selected'
					/>
				)}
			</ImageModal>
			<div className={styles.searchContainer}>
				<ListHeadingWithSearch searchAction={handleSearchSubmit}>
					Image Gallery
				</ListHeadingWithSearch>
			</div>
			{loading ? (
				<div className={styles.spinnerContainer}>
					<Spinner />
				</div>
			) : (
				<>
					<div className={styles.gridContainer}>
						{filteredImages.map((image, index) => (
							<div
								key={image.imageID}
								className={styles.gridItem}
								onClick={() => handleImageClick(image)}
								role='button'
								tabIndex={0}
								aria-label={`Image ${index + 1}`}>
								<img
									src={`data:image/jpeg;base64,${image.base64_thumbnail}`}
									alt={`Images ${index + 1}`}
								/>
							</div>
						))}
					</div>
				</>
			)}
			<div className={styles.paginationContainer}>
				<PaginationGroup
					activePage={currentPage}
					numberOfPages={totalPages}
					updatePageClicked={onPageChange}
				/>
			</div>
		</WhiteMainBackground>
	);
};

export default ImageGrid;
