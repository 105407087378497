import React, { useRef, useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faDownload,
	faCopy,
	faTrash,
	faEye,
} from '@fortawesome/free-solid-svg-icons';
import styles from './ImageModal.module.css';
import UserData from '../../../services/UserData';
import Permissions from '../../../services/Permissions';
import AIImageService from '../../../services/AIImageService';

const ImageModal = ({
	isOpen,
	onClose,
	prompt,
	onDownload,
	onCopy,
	onDelete,
	onEdit,
	children,
	imageId,
}) => {
	const downloadRef = useRef(null);
	const userData = useContext(UserData);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (downloadRef.current && !downloadRef.current.contains(event.target)) {
				// No need to set showDownloadOptions to false as it's removed
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	if (!isOpen) return null;

	const handleDelete = () => {
		if (window.confirm('Are you sure you want to delete this image?')) {
			if (typeof onDelete !== 'function') {
				alert('Error: onDelete is not a function');
				return;
			}
			onDelete(imageId)
				.then(() => {
					onClose();
				})
				.catch((error) => {
					alert('Failed to delete the image. Please try again.');
				});
		}
	};

	const deleteButtonElement = Permissions.displayIfAnyOfTheRolesAreAllowed(
		userData,
		AIImageService.deleteRoles,
		<button className={styles.deleteButton} onClick={handleDelete}>
			<FontAwesomeIcon icon={faTrash} /> Delete
		</button>
	);

	return (
		<div className={styles.modalOverlay} onClick={onClose}>
			<div className={styles.modal}>
				<div
					className={styles.modalContent}
					onClick={(e) => e.stopPropagation()}>
					<span className={styles.closeButton} onClick={onClose}>
						&times;
					</span>
					<div className={styles.mainContent}>
						{children}
						<div className={styles.content}>
							<h3>PROMPT</h3>
							<p>{prompt}</p>
							<div className={styles.buttonGroup}>
								<button className={styles.downloadButton} onClick={onDownload}>
									<FontAwesomeIcon icon={faDownload} /> Download
								</button>
								<button onClick={onCopy}>
									<FontAwesomeIcon icon={faCopy} /> Copy Prompt
								</button>
								<button className={styles.viewImageButton} onClick={onEdit}>
									<FontAwesomeIcon icon={faEye} /> View
								</button>
								{deleteButtonElement}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ImageModal;
