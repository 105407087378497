import { useLocation, useNavigate, useParams } from "react-router-dom";
import WhiteMainBackground from "../../shared_components/WhiteMainBackground/WhiteMainBackground";
import style from './GeneratedAssetDetail.module.css';
import MainHeading from "../../shared_components/MainHeading/MainHeading";
import { useContext, useEffect, useState, useCallback } from "react";
import GeneratorService from "../../services/GeneratorService";
import UserData from "../../services/UserData";
import GeneratedAssetList from "./GeneratedAssetList/GeneratedAssetList";
import Spinner from "../../shared_components/Spinner/Spinner";
import InfoPage from "../InfoPage/InfoPage";
import Utils from "../../utils/Utils";
import AssetService from "../../services/AssetService";
import GeneratedAssetDetails from "./GeneratedAssetDetails/GeneratedAssetDetails";

const GeneratedAssetDetail = () => {
  const userData = useContext(UserData);
  const { assetType, assetKey } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  let rows = 1;

  const getFromStateOrDefault = (key, defaultValue) => {
    if (location.state === null || location.state[key] === null) {
      return defaultValue;
    }
    return location.state[key];
  }

  const referrer = getFromStateOrDefault('referrer', "/plugins");
  const asset = getFromStateOrDefault('asset', null);
  const jobDetails = getFromStateOrDefault('jobDetails', {});
  
  const [jobData, updateJobData] = useState(jobDetails);
  const [loading, updateLoading] = useState(true);
  const [error, updateError] = useState(false);
  const [errorDetails, updateErrorDetails] = useState({});
  const [assetData, updateAssetData] = useState(asset);
  const [showEditButton, updateShowEditButton] = useState(false);

  const backButtonAction = () => {
    navigate(referrer);
  }

  const isEmpty = (value) => {
    return (value === null || value === undefined);
  }

  const listIsEmpty = (list) => {
    return (isEmpty(list) || list.length === 0);
  }

  const getList = (url, title, list) => {
    return <GeneratedAssetList url={url} title={title} list={list} assetKey={assetKey}/>;
  }

  const getInteractiveFiles = () => {
    if (isEmpty(jobData) || listIsEmpty(jobData.interactiveDownloadPaths)) {
      return null;
    }    

    rows++;
    return getList("interactive", "Interactive Files", jobData.interactiveDownloadPaths.sort());
  }

  const getAssessmentFiles = () => {
    if (isEmpty(jobData) || listIsEmpty(jobData.assessmentDownloadPaths)) {
      return null;
    }
    rows++;
    return getList("assessment", "Assessment Files", jobData.assessmentDownloadPaths.sort());
  }

  const getMediaFiles = () => {
    if (isEmpty(jobData) || listIsEmpty(jobData.mediaDownloadPaths)) {
      return null;
    }
    rows++;
    return getList("audio", "Audio Files", jobData.mediaDownloadPaths.sort());
  }

  const canEdit = useCallback(() => {
    return !Utils.userIsViewOnly(userData);
  }, [userData])

  const checkAssetJob = useCallback((data) => {
    AssetService.getAssetDetail(data.guid, userData).then(assetResponseData => {
        updateAssetData(assetResponseData);
        updateShowEditButton(canEdit());
        updateLoading(false);
      }).catch(error => {
        updateLoading(false);
        updateErrorDetails({
          reason: "Unable to get asset details",
          description: error.message
        })
      })
  }, [userData, canEdit]);

  const checkGeneratorJob = useCallback(() => {
    GeneratorService.checkJob(assetKey, userData.jwtToken, assetType).then(data => {
      checkAssetJob(data);
      updateJobData(data);
    }).catch(error => {
      updateError(true);
      updateLoading(false);
      updateErrorDetails({
        reason: "Unable to get asset details",
        description: error.message
      })
    })
  }, [assetKey, userData, assetType, checkAssetJob]);

  useEffect(() => {
    checkGeneratorJob();
  }, [checkGeneratorJob]);

  const getRowSplit = () => {
    if (error || loading) {
      return style.rows_1;
    }

    switch(rows) {
      case 1: return style.rows_1;
      case 2: return style.rows_2;
      case 3: return style.rows_3;
      default: return null;
    }
  }

  const interactiveFiles = getInteractiveFiles();
  const assessmentFiles = getAssessmentFiles();
  const mediaFiles = getMediaFiles();

  const splitRows = getRowSplit();

  const isNotReady = () => {
    return Utils.isNull(interactiveFiles) &&
          Utils.isNull(assessmentFiles) &&
          Utils.isNull(mediaFiles);
  }

  const getAssetView = () => {
    if (loading) {
      return <Spinner />
    }

    if (error) {
      return <InfoPage reason={errorDetails.reason} description={errorDetails.description} />
    }

    if (isNotReady()) {
      return (
        <>
          <GeneratedAssetDetails assetDetails={assetData} />
          <InfoPage title="INFO" reason="Asset not ready" description={`Asset status is ${jobData.jobStatus}`} />
        </>
        
      )
    }

    return (
      <>
        <GeneratedAssetDetails assetDetails={assetData} />
        {interactiveFiles}
        {assessmentFiles}
        {mediaFiles}
      </>
    )
  }

  const showEditView = () => {
    const prefix = assetType === "interactive" ? "plugins" : "audio";
    navigate(`/asset/${assetType}/${assetKey}`, { state: {
      referrer: `/${prefix}/asset/${assetType}/${assetKey}`
    }});
  }

  const assetView = getAssetView();
  const name = (isEmpty(assetData) || isEmpty(assetData.name)) ? "" : assetData.name;
  const editAction = showEditButton ? showEditView : null;

  return (
    <WhiteMainBackground>
      <div className={`${style.main} ${splitRows}`}>
        <MainHeading 
          editButtonAction={editAction}
          backButtonAction={backButtonAction}
        >{name}</MainHeading>
        {assetView}
      </div>
    </WhiteMainBackground>
  )
}

export default GeneratedAssetDetail;